<template>
  <div class="preview-code-dialog">
    <el-dialog
      :visible.sync="dataset.show"
      :before-close="closeHandler"
      width="1200px"
      title="查看代码"
      :destroy-on-close="true"
      :close-on-click-modal="false"
    >
      <div class="editor-container">
        <ace-editor
          v-model="dataset.code"
          @init="editorInit"
          lang="vue"
          theme="chrome"
          style="border: 1px solid #ebecec"
          height="100%"
          width="100%"
        >
        </ace-editor>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import AceEditor from "vue2-ace-editor";

export default {
  name: "preview-code-dialog",
  components: {
    AceEditor,
  },
  props: {
    dataset: {
      type: Object,
      default() {
        return {
          show: false,
        };
      },
    },
  },
  methods: {
    closeHandler() {
      this.$emit("close");
    },
    editorInit(thingEditor) {
      require("brace/ext/language_tools"); //language extension prerequsite...
      require("brace/mode/json");
      require("brace/mode/javascript"); //language
      require("brace/mode/python");
      require("brace/theme/chrome");
      thingEditor.setShowPrintMargin(false);
      thingEditor.setOption("readOnly", true);
    },
  },
};
</script>

<style lang="less" scoped>
.preview-code-dialog {
  .editor-container {
    height: 600px;
    width: 100%;
    margin-bottom: 30px;
  }
}
</style>