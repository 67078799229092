<template>
  <div class="indicator-record">
    <div class="header-line">
      <div class="back-div" @click="routeBack">
        <img src="@/assets/images/go_back_icon.png" alt="" />
        <span>返回</span>
      </div>
      <div class="border-line"></div>
      <div class="name-div">
        <span class="name-label">
          {{ `${form.cardName} - 发布记录` }}
        </span>
      </div>
    </div>
    <div class="main-container">
      <div class="left-part">
        <div class="title-label">发布记录</div>
        <div class="no-data" v-if="!cardList.length">暂无发布记录...</div>
        <div class="card-list" v-else v-loading="loading">
          <div
            :class="['single-card', item.active && 'single-card-active']"
            v-for="item in cardList"
            :key="item.versionId"
            @click="setActiveCard(item)"
          >
            <div class="title-line" :title="item.cardName">
              {{ `${item.updateUserName} 发布了 ${item.cardName}` }}
            </div>
            <div class="date-line">
              {{ item.lastUpdateDate | dateFormat("YYYY-MM-DD HH:mm:ss") }}
            </div>
            <div class="img-line">
              <img :src="imgUrlRender(item)" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div class="right-part">
        <div class="button-line" v-if="this.cardList.length">
          <r-button @click="codePreview">查看代码</r-button>
          <r-button @click="releaseBack">还原</r-button>
          <r-button plain @click="copyHandler">创建副本</r-button>
        </div>
        <div class="preview-line">
          <div
            class="preview-inner"
            :style="{
              width: widthRender(activeItem),
            }"
          >
            <SingleCard v-if="activeItem.cardConfig" :dataset="activeItem" />
          </div>
        </div>
        <div class="description-line">
          <div class="description-label">发布描述：</div>
          <div class="inner">
            {{ activeItem.publishDescription || "暂无发布描述" }}
          </div>
        </div>
      </div>
    </div>
    <PreviewCodeDialog
      :dataset="previewDialog"
      @close="
        () => {
          previewDialog.show = false;
        }
      "
    />
  </div>
</template>

<script>
import { getPublishRecord, cardSave } from "@/api/ruge/bms/indicatorManagement";
import { envInfo } from "@/constants/envInfo";
import SingleCard from "../../vlink/dashboardEdit/components/singleCard.vue";
import PreviewCodeDialog from "./components/previewCodeDialog.vue";

export default {
  name: "indicator-record",
  components: {
    SingleCard,
    PreviewCodeDialog,
  },
  data() {
    return {
      loading: false,
      activeIndex: 0,
      form: {
        cardName: "",
        publishDescription: "",
      },
      activeItem: {},
      cardList: [],
      previewDialog: {
        show: false,
        code: null,
      },
    };
  },
  created() {
    this.initDatas();
  },
  methods: {
    widthRender(datas) {
      const { cardSize } = datas || {};
      if (cardSize) {
        const parsedSize = JSON.parse(cardSize);
        return `${parsedSize.mode * 33}%`;
      }
      return "100%";
    },
    codePreview() {
      this.previewDialog.show = true;
      this.previewDialog.code = this.activeItem.cardConfig;
    },
    releaseBack() {
      this.$confirm(`是否确认还原? `, this.$t("commons.warning"), {
        confirmButtonText: this.$t("commons.confirm"),
        cancelButtonText: this.$t("commons.cancel"),
        type: "warning",
      })
        .then(() => {
          this.publishHander();
        })
        .catch((error) => {
          console.log(`未删除，原因 => ${error}`);
        });
    },
    copyHandler() {
      const { cardId, versionId } = this.activeItem;
      this.$router.push({
        path: "/bms/indicatorEdit",
        query: {
          cardId,
          versionId,
          layout: "hide",
          type: "copy",
        },
      });
    },
    setActiveCard(setItem) {
      this.cardList.forEach((item, index) => {
        if (item.versionId === setItem.versionId) {
          item.active = true;
          this.activeIndex = index;
        } else {
          item.active = false;
        }
      });
      this.activeItem = {};
      this.$nextTick(() => {
        this.activeItem = setItem;
      });
    },
    publishHander() {
      const params = {
        ...this.activeItem,
        ...{
          cardStatus: "DRAFT", // 还原状态都改为draft
        },
      };
      cardSave(params).then((res) => {
        this.$message.success("还原成功");
        this.initDatas(res.cardName);
      });
    },
    initDatas(setName) {
      const { cardId, cardName } = this.$route.query;
      this.form.cardName = setName || cardName;
      if (!cardId) return;
      this.loading = true;
      getPublishRecord({ cardId })
        .then((res) => {
          this.cardList = res;
          if (res && res.length) {
            this.setActiveCard(res[0]);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    routeBack() {
      this.$router.push({
        path: "/bms/indicatorManagement",
        query: {
          cardId: this.$route.query.cardId,
        },
      });
    },
    imgUrlRender({ coverPicture, cardType }) {
      if (coverPicture) {
        if ([1, 2, 3].includes(coverPicture)) {
          return require(`../../../../assets/images/bms/card_example_${coverPicture}.png`);
        }
        return `${envInfo.bgApp.archivePath}/param/archive/download?dlType=DefaultDownload&fType=image&fi=${coverPicture}`;
      } else {
        const fileId = { bar: 1, pie: 2, line: 3 }[cardType];
        return require(`../../../../assets/images/bms/card_example_${fileId}.png`);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.indicator-record {
  background: #ffffff;
  .header-line {
    height: 70px;
    line-height: 70px;
    background: #f7f8fa;
    display: flex;
    align-items: center;
    padding: 0 40px;
    border-bottom: 1px solid #e3e8ee;
    .back-div {
      cursor: pointer;
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 18px;
      color: #2a61ff;
      img {
        margin-right: 4px;
        position: relative;
        top: -1px;
      }
      span {
        font-weight: 600;
      }
    }
    .border-line {
      width: 1px;
      height: 12px;
      background: #cbdbe9;
      margin: 0 10px;
    }
    .name-div {
      display: flex;
      align-items: center;
      .name-label {
        display: inline-block;
        max-width: 375px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-weight: 600;
        font-size: 18px;
        color: #252d3d;
      }
    }
  }
  .main-container {
    height: calc(100vh - 70px);
    width: 100%;
    display: flex;
    padding: 20px;
    justify-content: space-between;
    .left-part {
      width: 360px;
      height: 100%;
      background: #f7f8fa;
      border-radius: 4px;
      padding: 20px 15px;
      .title-label {
        font-weight: 600;
        font-size: 16px;
        color: #252d3d;
        height: 30px;
        line-height: 18px;
      }
      .no-data {
        padding: 10px 0;
      }
      .card-list {
        height: calc(100% - 30px);
        overflow: auto;
        .single-card {
          width: 320px;
          height: 182px;
          border-radius: 10px;
          background: #ffffff;
          border: 1px solid #f9f9f9;
          margin-bottom: 12px;
          cursor: pointer;
          padding: 16px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .title-line {
            font-weight: 600;
            font-size: 14px;
            color: #252d3d;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
          .date-line {
            font-weight: 400;
            font-size: 14px;
            color: #a7b4c7;
          }
          .img-line {
            img {
              width: 288px;
              height: 100px;
            }
          }
        }
        .single-card-active {
          border: 1px solid #2a61ff;
        }
      }
    }
    .right-part {
      flex: 1;
      margin-left: 20px;
      background: #f7f8fa;
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 20px 30px;
      .button-line {
        margin-bottom: 20px;
        text-align: right;
      }
      .preview-line {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        .preview-inner {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          overflow: auto;
        }
      }
      .description-line {
        .inner {
          line-height: 22px;
          text-indent: 75px;
        }
        .description-label {
          font-size: 14px;
          font-weight: bold;
          position: absolute;
          top: 14px;
          left: 14px;
        }
        position: relative;
        margin-top: 10px;
        width: 100%;
        height: 88px;
        padding: 10px;
        background: #ffffff;
        border-radius: 10px;
        color: #5d687c;
        overflow: auto;
        font-size: 14px;
      }
    }
  }
}
</style>