var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "preview-code-dialog" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dataset.show,
            "before-close": _vm.closeHandler,
            width: "1200px",
            title: "查看代码",
            "destroy-on-close": true,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.dataset, "show", $event)
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "editor-container" },
            [
              _c("ace-editor", {
                staticStyle: { border: "1px solid #ebecec" },
                attrs: {
                  lang: "vue",
                  theme: "chrome",
                  height: "100%",
                  width: "100%",
                },
                on: { init: _vm.editorInit },
                model: {
                  value: _vm.dataset.code,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataset, "code", $$v)
                  },
                  expression: "dataset.code",
                },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }